/*
 * @Author: Zhanpeng Zhu wb.zhuzp01@rd.netease.com
 * @Date: 2024-08-06 10:15:33
 * @LastEditors: Zhanpeng Zhu wb.zhuzp01@rd.netease.com
 * @LastEditTime: 2025-03-17 10:28:33
 * @FilePath: /devsys/WebRootSource/common/gw-head/main.comp.ts
 * @Description: 
 */
/**
 *
 *
 * @author fuyg
 * @date  2020-09-28
 */

declare global {
  interface Window {
    uetq: any[];
  }
}
import { createComponentBroadcast } from '../../common-scripts/componentBroadcast'
import ursLoginModal from '../urs-login-modal/ursLoginModal'
import { requestMethod } from '../../common-scripts/request'
import loginSuccMethods from '../../common-scripts/ursLoginSuccMethod'
import logger from '@/common-scripts/logger'

$(function() {
  const { actions } = ursLoginModal
  const modalBroadcast = createComponentBroadcast(ursLoginModal)
  function register() {
    window.uetq = window.uetq || []
    const customData =  { user: 'demoUser', phone: 1234567789 }
    console.log('customData', customData)
    window.uetq.push('event', 'signup',customData)
  }

  $('.login-urs').click(function() {
    register()
    const data = {
      autoLogin: true,
    }
    requestMethod('/j_spring_security_check', 'POST', data)
      .then((res) => {
        const { code } = res
        if (code == 200) {
          if (res.register) {
            loginSuccMethods(res)
          } else {
            modalBroadcast.trigger(actions.show)
          }
        }
      })
      .catch((err) => {
        modalBroadcast.trigger(actions.show)
        logger.log(err)
      })
    modalBroadcast.trigger(actions.show)
  })
})
